<template>
  <div class="chat-tasks-page">
    <!-- <van-tree-select
      height="100vh"
      :items="items"
      :main-active-index.sync="active"
    >
      <template v-if="items.length" #content>
        <Content :taskCode="items[active].taskCode" @reLoadCount="init" />
      </template>
    </van-tree-select> -->
    <div class="tree-select">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
        :class="{ active: taskCode === item.taskCode }"
        @click="taskCode = item.taskCode"
      >
        <p>{{ item.text }}</p>
        <p>{{ item.badge }}</p>
      </div>
    </div>
    <div class="tree-content">
      <Content v-if="taskCode" :taskCode="taskCode" @reLoadCount="init" />
    </div>
  </div>
</template>
<script>
import loading from '@/utils/loading'
import Content from './groupChats'
import TodoServices from '@/services/todoServices.js'

export default {
  components: {
    Content,
  },

  data() {
    return {
      // active: 0,
      taskCode: '',
      items: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      loading.showLoading()
      TodoServices.getQwTaskTotal().then(
        (data) => {
          loading.hideLoading()
          if (data && data.length) {
            if (!this.taskCode) {
              this.taskCode = data[0].taskCode
            }
            this.items = data.map(({ count, taskCode, taskName }) => ({
              badge: `(${count})`,
              text: taskName,
              taskCode,
            }))
          }
        },
        () => {
          loading.hideLoading()
        }
      )
    },
  },
}
</script>
<style lang="less" scoped>
.chat-tasks-page {
  display: flex;
  height: 100vh;
  .tree-select {
    overflow-y: auto;
    flex-shrink: 0;
    background: #f7f8fa;
    .item {
      text-align: center;
      padding: 10px 16px;
      &.active {
        background: white;
        color: #b9921a;
      }
    }
  }
  .tree-content {
    flex: 1;
    overflow-y: auto;
  }
}
// /deep/ .van-tree-select__nav {
//   flex: none !important;
//   width: auto !important;
// }
// /deep/ .van-sidebar-item--select {
//   color: #b9921a;
// }

// /deep/ .van-tree-select__content {
//   background: inherit;
//   flex: 1;
// }
// /deep/ .van-sidebar-item--select::before {
//   display: none;
// }
// /deep/ .van-info {
//   position: static;
//   transform: translate(0, 0);
//   background: transparent;
//   color: inherit;
//   border: none;
// }
</style>
